<template>
  <div id="freelancerDiv" style=" padding-top: 60px; background: #f5f6f8;">
    <div class="hiddendiv"></div>

    <div class="hiddendiv"></div> <div class="hiddendiv"></div>
    <div class="pageContainer" style="width: 900px!important; padding-bottom: 100px; padding-top: 40px;">

      <div style=" font-size: 16px; width: 900px; margin: 0 auto;">
        <div class="content-white">



          <div class="page-header" style=" text-align: center; margin-left:0;margin-top:0px;margin-bottom: 10px;" >Neye ihtiyacın var?</div>
          <div class="hiddendiv"></div>
          <div style="text-align: center">
            <router-link to="/become_a_freelancer">
              <button class="super-button-light" style="padding: 24px!important; height: 80px; font-size: 18px;">Freelancer  olmak istiyorum.</button>
            </router-link>
            <router-link to="/become_a_startup">
              <button style="margin-left: 20px; padding: 24px!important; height: 80px; font-size: 18px;" class="super-button-light">Projem için bir freelancer'a ihtiyacım var.</button>
            </router-link>
          </div>

          <div class="hiddendiv"></div> <div class="hiddendiv"></div>

        </div>
      </div>


    </div>
    <div class="hiddendiv"></div>
  </div>
</template>

<script>

  export default {
    name: "src-pages-body-onboarding-main-v1",
    components: {

    },

    data() {
      return {

      }
    },

    methods: {

    },

    computed: {

    }
  }
</script>

<style scoped>

   .super-button-light:hover{
     background-color: #2d3640!important;
     color: white!important;
   }
</style>
